import React from "react";
import { motion } from "framer-motion";
import { NavLink, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive'

const Header = ({ onWorkTogetherClick }) => {
    const navigate = useNavigate();

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const whatsappMessage = "Viluxur Holidays"

    const handleWorkTogether = (e) => {
        e.preventDefault();
        // Navigate to contact page
        navigate(`${process.env.PUBLIC_URL}/contact-us`);
        // After navigation, trigger the modal
        if (onWorkTogetherClick) {
            setTimeout(() => {
                onWorkTogetherClick();
            }, 100); // Small delay to ensure navigation is complete
        }
    };

    return (
        <div>
            {isDesktopOrLaptop &&
                <motion.div
                    initial={{ opacity: 0, y: -180 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{
                        ease: "easeInOut",
                        duration: 1,
                        delay: 0.6,
                    }}
                    className="header"
                >
                    <motion.div className="header-inner">
                        <div className="logo">
                            <NavLink to={`${process.env.PUBLIC_URL}/`} key={`${process.env.PUBLIC_URL}/`}  >
                                <span className="logo"> Viluxur Holidays </span>
                            </NavLink> </div>
                        <nav className="nav">
                            <li>
                                <NavLink to={`${process.env.PUBLIC_URL}/5s-of-luxury`} key={`${process.env.PUBLIC_URL}/5s-of-luxury`}  >
                                    <div className="flip-animate" ><span data-hover="5 Ss of Luxury">5 Ss of Luxury</span> </div>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={`${process.env.PUBLIC_URL}/team`} key={`${process.env.PUBLIC_URL}/team`}  >
                                    <div className="flip-animate" ><span data-hover="Team">Team</span> </div>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={`${process.env.PUBLIC_URL}/rebuild-now`} key={`${process.env.PUBLIC_URL}/rebuild-now`}  >
                                    <div className="flip-animate" ><span data-hover="Rebuild NOW">Rebuild NOW</span> </div>
                                </NavLink>
                            </li>
                        </nav>
                        <div className="contact">
                            <a href="#" 
                               onClick={handleWorkTogether}
                               className="work-together-link"
                            >
                                Let's work together
                            </a>
                        </div>
                    </motion.div>
                </motion.div>
            }

            {isTabletOrMobile &&
                <motion.div
                    initial={{ opacity: 0, y: -180 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{
                        ease: "easeInOut",
                        duration: 1,
                        delay: 0.6,
                    }}
                    className=""
                >
                    <motion.div>
                        <div className="container">
                            <div className="row">
                                <div className="col-6 header-logo">
                                    <NavLink to={`${process.env.PUBLIC_URL}/`} key={`${process.env.PUBLIC_URL}/`}  >
                                        <img src={process.env.PUBLIC_URL + `/images/Viluxur_Holidays_logo_new_gold.svg`} />
                                    </NavLink>
                                </div>
                                <div className="col-6 flex">
                                    <nav role="navigation">
                                        <div id="menuToggle">
                                            <input type="checkbox" />
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            <ul id="menu">
                                                <li className="header-logo mb-5">
                                                    <NavLink to={`${process.env.PUBLIC_URL}/`} key={`${process.env.PUBLIC_URL}/`}  >
                                                        <img src={process.env.PUBLIC_URL + `/images/Viluxur_Holidays_logo_new_gold.svg`} />
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to={`${process.env.PUBLIC_URL}/5s-of-luxury`} key={`${process.env.PUBLIC_URL}/5s-of-luxury`}  >
                                                        <div className="arrow-righ">We are viluxur </div>
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to={`${process.env.PUBLIC_URL}/team`} key={`${process.env.PUBLIC_URL}/team`}  >
                                                        <div className="arrow-righ">We are local global</div>
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to={`${process.env.PUBLIC_URL}/rebuild-now`} key={`${process.env.PUBLIC_URL}/rebuild-now`}  >
                                                        <div className="arrow-righ">We are the future</div>
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to={`${process.env.PUBLIC_URL}/vh-vip`} key={`${process.env.PUBLIC_URL}/vh-vip`}  >
                                                        <div className="arrow-righ">Viluxur Vip
                                                        </div>
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to={`${process.env.PUBLIC_URL}/vh-experience`} key={`${process.env.PUBLIC_URL}/vh-experience`}  >
                                                        <div className="arrow-righ">Viluxur Experience</div>
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to={`${process.env.PUBLIC_URL}/contact-us`} key={`${process.env.PUBLIC_URL}/contact-us`}  >
                                                        <div className="arrow-righ">Let's work together</div>
                                                    </NavLink>
                                                </li>
                                                <li className="bottom-menu bottom-menu-item">
                                                </li>
                                                <li className="email font-small m-header-top">
                                                    <a href="mailto:info@viluxurholidays.com">info@viluxurholidays.com</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                </motion.div>
            }
        </div>
    );
};

export default Header;
